var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "text-truncate",
    attrs: {
      headers: _vm.headersData,
      items: _vm.fetchingData ? [] : _vm.columnsData,
      loading: _vm.fetchingData,
      "item-key": "short_id",
      "sort-desc": [true, true],
      "hide-default-footer": "",
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-icon", { attrs: { left: "" } }, [
                  _vm._v("mdi-table-large"),
                ]),
                _c(
                  "span",
                  { staticClass: "secondary--text font-weight-bold" },
                  [_vm._v(_vm._s(_vm.tableName))]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "no-data",
        fn: function () {
          return [
            _c(
              "v-alert",
              {
                staticClass: "mt-3",
                attrs: { text: "", prominent: "", color: "info", icon: "info" },
              },
              [
                _vm.fetchingData
                  ? _c("span", [_vm._v("Loading column data, please wait...")])
                  : _c("span", [_vm._v("No data available.")]),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }